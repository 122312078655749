import { useSession } from 'next-auth/react';

export function Auth({ children }) {
  const { status } = useSession({ required: true });

  if (status === 'loading') {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-primary-500" />
      </div>
    );
  }

  return children;
}
