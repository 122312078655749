"use client";

import { TooltipProvider } from "@radix-ui/react-tooltip";
import { TRPCReactProvider } from "@src/trpc/react";
import { SessionProvider } from "next-auth/react";
import { ApolloWrapper } from "../ApolloProvider";
import { Auth } from "./auth";
import { NavigationWrapper } from "./navigationWrapper";

const composeProviders =
  (...providers) =>
  (props) =>
    providers.reduceRight(
      (children, Provider) => <Provider>{children}</Provider>,
      props.children
    );

export const AllProviders = composeProviders(
  ApolloWrapper,
  SessionProvider,
  TRPCReactProvider,
  Auth,
  TooltipProvider
);

export default function ClientLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <AllProviders>
      <NavigationWrapper>{children}</NavigationWrapper>
    </AllProviders>
  );
}
