import "@/styles/globals.css";
import { Toaster } from "react-hot-toast";
import { AllProviders } from "@/components/Layout/clientLayout";
function MyApp({ Component, pageProps }) {
  return (
    <AllProviders>
      <Component {...pageProps} />
      <Toaster />
    </AllProviders>
  );
}

export default MyApp;
